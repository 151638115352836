import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { ValidationObserver } from 'vee-validate'
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'

@Component({
  name: 'GtrNewCompanyForm'
})
export default class GtrNewCompanyForm extends Vue {
    @Ref()
    readonly observerForm!: InstanceType<typeof ValidationObserver>

    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean|undefined

    data () {
      return {
        submitting: false,
        value: false,
        company: {
          name: null,
          owner_uuid: null,
          logo: null,
          logoCropped: null
        },
        logoSrc: null,
        rulesFailed: false,
        rules: [
          (value: any) => !value || value.size < 2000000 || 'Logo size should be less than 2 MB.',
          (value: any) => {
            return !value || ['image/png', 'image/jpeg', 'image/bmp'].includes(value.type) || 'Please select a valid image.'
          }
        ],
        owners: []
      }
    }

    async mounted () {
      try {
        const response = await this.$store.dispatch('adminUser/fetchAdminUsers')
        if (response) {
          response.map((user: any) => {
            this.$data.owners.push({
              value: user.uuid,
              text: user.name
            })
          })
        }
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      }
      this.clearForm()
    }

    @Watch('visible', { immediate: true })
    onVisibilityChange (payload: any) {
      this.$data.value = payload
    }

    onClose () {
      this.$data.value = false
      const observer = (this.$refs.observerForm as any)
      observer.reset()
      this.$data.company = {
        name: null,
        logo: null,
        logoCropped: null
      }
      this.$emit('close')
    }

    onLogoChange (payload: any) {
      if (payload) {
        // this.$data.logoSrc = URL.createObjectURL(payload)
      }
    }

    imageCrop () {
    //   (this.$refs.cropper as any).getCroppedCanvas().toBlob((blob: any) => {
    //     if (blob) {
    //       const formData = new FormData()
    //       formData.append('croppedImage', blob, this.$data.company.logo.name)
    //       this.$data.company.logo = formData
    //     }
    //   })
    }

    async submit () {
      const form = (this.$refs.newCompanyForm as HTMLFormElement)
      if (form.validate()) {
        try {
          this.$data.submitting = true
          await this.$store.dispatch('company/registerCompany', this.$data.company)
          Container.get(Notification).success('Company successfully created.')
          this.$bus.$emit('gtr-company-created')
          this.onClose()
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        } finally {
          this.clearForm()
          this.$data.submitting = false
        }
      }
    }

    private clearForm () {
      this.$data.company = {
        name: null,
        logo: null,
        owner_uuid: null
      }
    }
}
