import { Component, Vue, Watch } from 'vue-property-decorator'
import GtrAdminLayout from '@/modules/common/views/layouts/level-one/admin/admin.layout.vue'
import { mapState } from 'vuex'
import GtrNewCompanyForm from './forms/new/new.form.vue'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import GtrUserFavorites from '@/modules/common/components/mixins/user-favorites.mixin'

@Component({
  name: 'GtrAdminCompaniesView',
  computed: {
    ...mapState('company', ['companies'])
  },
  components: {
    'gtr-new-company-form': GtrNewCompanyForm
  }
})
export default class GtrAdminCompaniesView extends GtrUserFavorites {
  data () {
    return {
      search: '',
      loading: false,
      table: {
        headers: [
          { text: 'Favorite', align: 'center', sortable: false, value: 'favorite', width: '64px' },
          { text: 'Company', align: 'start', sortable: true, value: 'name' },
          { text: 'Owner Name', value: 'owner.name' },
          { text: 'Owner Email', value: 'owner.email' },
          { text: 'Actions', value: 'actions', searchable: false, sortable: false, width: '90px' }
        ],
        items: []
      },
      forms: {
        showNewCompanyForm: false
      },
      companyToDelete: null
    }
  }

  created () {
    this.$bus.$on('gtr-company-created', this.fetchCompanies)
    this.$bus.$on('gtr-load-companies', this.fetchCompanies)
    this.$bus.$on('gtr-company-edit', this.fetchCompanies)
    this.$emit('update:layout', GtrAdminLayout)
  }

  async mounted () {
    await this.fetchCompanies()
  }

  handleViewCompany (company: any) {
    this.$store.commit('company/SET_COMPANY', company)
    this.$router.push({ name: 'level-two.company.dashboard', params: { uuid: company.uuid } })
  }

  handleShowNewCompanyForm () {
    this.$data.forms.showNewCompanyForm = true
  }

  handleCloseNewCompanyForm () {
    this.$data.forms.showNewCompanyForm = false
  }

  handleDeleteCompany (payload: any) {
    this.$data.companyToDelete = payload
  }

  async handleDeleteCompanyAction (payload: any) {
    if (payload.confirm) {
      try {
        await this.$store.dispatch('company/deleteCompany', { uuid: this.$data.companyToDelete.uuid })
        Container.get(Notification).success('Company successfully deleted.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      }
    }
    this.$data.companyToDelete = null
  }

  async fetchCompanies () {
    try {
      this.$data.loading = true
      this.$store.commit('company/SET_COMPANIES', [])
      await this.$store.dispatch('company/fetchCompanies')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
